import React from 'react';
import "./contant.css";
import { useTranslation } from 'react-i18next';
import pagesData from '../../../assets/Data/Features.json'; // استيراد ملف JSON
import { useParams } from 'react-router-dom';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';

const ContantQRPayment = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  const { nomFeatures } = useParams();
  new WOW().init();

  const pageData = pagesData[nomFeatures];

  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';

  return (
    <>
      <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>
        <h2>{t('scan_and_pay_title')}</h2>
        <p>{t('convenient_ways_to_pay')}</p>

        <h3>{t('dynamic_qr_codes_title')}</h3>
        <p>{t('dynamic_qr_codes_description')}</p>
        <ul>
          <li>{t('public_address')}</li>
          <li>{t('amount')}</li>
          <li>{t('memo_optional')}</li>
        </ul>
        <p>{t('when_to_use_dynamic_qr_codes')}</p>
        <ul>
          <li>{t('variable_payments')}</li>
          <li>{t('recurring_payments')}</li>
          <li>{t('invoicing')}</li>
        </ul>
        <p>{t('benefits_of_dynamic_qr_codes')}</p>
        <ul>
          <li>{t('flexibility')}</li>
          <li>{t('real_time_information')}</li>
        </ul>

        <h3>{t('static_qr_codes_title')}</h3>
        <p>{t('static_qr_codes_description')}</p>
        <p>{t('how_they_work')}</p>
        <ol>
          <li>{t('sender_scans_code')}</li>
          <li>{t('retrieve_public_key')}</li>
          <li>{t('manual_entry')}</li>
          <li>{t('transaction_initiated')}</li>
        </ol>
      </div>
    </>
  );
};

export default ContantQRPayment;
