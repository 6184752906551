import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import pagesData from '../../../assets/Data/Features.json';
import "./BreadcrumbsForFeatruesPages.css";
import { useTranslation } from 'react-i18next';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
import security from '../../../assets/images/logo/security.png';
import efficiency from '../../../assets/images/logo/efficiency.png';
import sdk from '../../../assets/images/logo/SDK.png';
import qr from '../../../assets/images/logo/QrBanner.png';
import banque from '../../../assets/images/logo/banque.png';
import multiSignature from '../../../assets/images/logo/multiSignature.png';

const BreadcrumbsForFeatruesPages = () => {
  const { nomFeatures } = useParams();
  const { t } = useTranslation();
  new WOW().init();

  const pageData = pagesData[nomFeatures];
  const [imageLoaded, setImageLoaded] = useState(false);
  let backgroundImage; // Declare backgroundImage here

  useEffect(() => {
    // Load image after component mounts
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = backgroundImage;
  }, [backgroundImage]); // useEffect dependency

  if (!pageData) {
    return <Navigate to="/error" />;
  }

  // Assign backgroundImage value inside the switch statement
  switch (nomFeatures) {
    case 'Security':
      backgroundImage = security;
      break;
    case 'Efficiency':
      backgroundImage = efficiency;
      break;
    case 'QRPayment':
      backgroundImage = qr;
      break;
    case 'PowerfulSDK':
      backgroundImage = sdk;
      break;
    case 'InterbankTransaction':
      backgroundImage = banque;
      break;
    case 'MultiSignature':
      backgroundImage = multiSignature;
      break;
    default:
      // If nomFeatures value doesn't match any case, use a default image or handle accordingly
      break;
  }

  return (
    <div className={`breadcrumbs ${imageLoaded ? 'loaded' : ''}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
      {!imageLoaded && <div className="loading-overlay"></div>}
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 col-md-12 col-12">
            <div className="breadcrumbs-content fadeInLeft wow">
              <h1 className="page-title">{t(pageData.title)}</h1>
              <ul className="breadcrumb-nav">
                <li><a href="/#features">{t(pageData.title)}</a></li>
                <li>{t("Features")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbsForFeatruesPages;