import React, { useEffect } from 'react';

import Header2 from '../../FeatruesPages/Header/Header';
import Footer2 from '../../FeatruesPages/Footer/Footer';
import Preloader from '../../Home/components/Preloader/Preloader';
import BreadcrumbsForProofOfReserves from './BreadcrumbsForProofOfReserves/BreadcrumbsForProofOfReserves';
import TransactionTable from './Tables/TransactionTable';
import ImageSlider from './ImageSlider';

function Transparency() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('Scrolled to top');
    }, 0);
  }, []);
  return (
    <>
<Preloader/>
<Header2/>
<BreadcrumbsForProofOfReserves/>
<ImageSlider/>
{/* <TransactionTable/> */}
<Footer2/>
    </>
  );
}
export default Transparency;
