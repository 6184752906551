import React from 'react';
import "./TransactionTable.css";
import { useTranslation } from 'react-i18next';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
function TransactionTable() {
    const handleDownload = (fileUrl) => {
        // Logic to handle file download goes here
        console.log(`Downloading file: ${fileUrl}`);
        window.open(fileUrl, '_blank');
    };
    const { t } = useTranslation();
    new WOW().init();

    const data = {
        headers: [t("Type"), t("Fees")],
        rows: [
            [t("in app transaction"), t("0 DA , 0%")], [t("Deposit"),t( "0 DA , 0% ")], [t("withdraw"),t( "0.5% + 50 DA + third party fees depending on the withdraw method")]
        ],
    };

    return (
        <div className='TransactionTable'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ud-section-title text-center mx-auto">
                            <h2 className='fadeInUp wow'>{t("Fees")}</h2>
                            <p className='fadeInLeft wow' data-wow-delay=".1s">
                                {t("currently there are no fees associated with in app transactions")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tableContant'>
                <table className="StyledTable">
                    <thead>
                        <tr>
                            <th>{data.headers[0]}</th>
                            <th>{data.headers[1]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.rows.map((row, index) => (
                            <tr key={index}>
                                <td>{row[0]}</td>
                                <td>
                                    {row[1]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="text-center mx-auto">
                    <p className='fadeInLeft wow' data-wow-delay=".1s">
                        {t("minimum deposit amount is 500 DA")}
                    </p>
                    <p className='fadeInLeft wow' data-wow-delay=".1s">
                        {t("minimum withdraw amount is 1000 DA")}
                    </p>
                    <p className='fadeInLeft wow' data-wow-delay=".1s">
                        {t("Please note that fees are subject to change")}
                    </p>
                </div>
            </div>

        </div>
    );
}

export default TransactionTable;
