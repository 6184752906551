import React from 'react';
import "./ِContantForFooter.css";
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';
import pagesData from '../../../assets/Data/Footer.json';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
const Innovation = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  const { nomFooter } = useParams();

  const pageData = pagesData[nomFooter];
  new WOW().init();

  if (!pageData) {
    return <Navigate to="/error" />;
  }
  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';

  return (
    <>
      <div className='mycard' style={isArabic ? {  direction: 'rtl' } : {}}>
        <h2>{t(pageData.title)}</h2>
        <br />
        <span>
         {t(pageData.description)}
        </span>
      </div>
    </>
  );
};
export default Innovation;