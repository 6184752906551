import React, { useEffect } from 'react';
import Header2 from './Header/Header';
import Footer2 from './Footer/Footer';
import Preloader from '../Home/components/Preloader/Preloader';
import BreadcrumbsForFeatruesPages from './BreadcrumbsForFeatruesPages/BreadcrumbsForFeatruesPages';
import { useParams, useNavigate } from 'react-router-dom';
import ContantInterbankTransaction from './contant/ContantInterbankTransaction.jsx';
import ContantPowerfulSDK from './contant/ContantPowerfulSDK.jsx';
import ContantQRPayment from './contant/ContantQRPayment.jsx';
import ContantMultiSignature from './contant/ContantMultiSignature.jsx';
import ContantEfficiency from './contant/ContantEfficiency.jsx';
import ContantSecurity from './contant/ContantSecurity.jsx';


function FeatruesPages() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('Scrolled to top');
    }, 0);
  }, []);
  const { nomFeatures } = useParams();
  let ContentComponent;
  switch (nomFeatures) {
    case 'InterbankTransaction':
      ContentComponent = ContantInterbankTransaction;
      break;
    case 'PowerfulSDK':
      ContentComponent = ContantPowerfulSDK;
      break;
    case 'QRPayment':
      ContentComponent = ContantQRPayment;
      break;
    case 'MultiSignature':
      ContentComponent = ContantMultiSignature;
      break;
    case 'Efficiency':
      ContentComponent = ContantEfficiency;
      break;
    case 'Security':
      ContentComponent = ContantSecurity;
      break;
    default:
      navigate('/error');
      return null;
  }
  return (
    <>
      <Preloader />
      <Header2 />
      <BreadcrumbsForFeatruesPages />
      <ContentComponent/>
      <Footer2 />
    </>
  );
}
export default FeatruesPages;
