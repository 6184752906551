import React, { useEffect } from 'react';
import "./ِContantForFooter.css";
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';
import pagesData from '../../../assets/Data/Footer.json';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
import Preloader from '../../Home/components/Preloader/Preloader';

const TermEtConditions = () => {
    const { t, i18n } = useTranslation();
    const { nomFooter } = useParams();

    const pageData = pagesData[nomFooter];
    new WOW().init();
    useEffect(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          console.log('Scrolled to top');
        }, 0);
      }, []);
    if (!pageData) {
        return <Navigate to="/error" />;
    }

    const isArabic = i18n.language === 'ar';

    return (
        <>
        <Preloader/>
            <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>
                <h2>{t('Privacy Policy for SofizPay Android App')}</h2>
                <p>{t('Last updated: 21/10/2023')}</p>
                <p>{t('Thank you for choosing SofizPay! Your privacy is important to us, and we are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our Android application.')}</p>
                <h3>{t('Collecting Personal Information')}</h3>
                <p>{t('We may collect, store, and use the following kinds of personal data about you:')}</p>
                <ul>
                    <li><strong>{t('Communication Data')}:</strong> {t('Information in or related to any communications you send to us, or through our website, and any other personal information you choose to share.')}</li>
                    <li><strong>{t('Service Data')}:</strong> {t('Information provided when using our Services, or generated during the use thereof.')}</li>
                    <li><strong>{t('Technical Data')}:</strong> {t('Includes IP address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.')}</li>
                </ul>
                <p>{t("We do not collect Special Categories of Personal Data (including details about race, ethnicity, beliefs, etc.), nor do we collect credit or debit card details.")}</p>
                <h3>{t('How Your Personal Data is Collected')}</h3>
                <p>{t('We use different methods to collect data from and about you, including:')}</p>
                <ul>
                    <li><strong>{t('Automated Technologies or Interactions')}:</strong> {t('As you interact with our website, we may automatically collect Technical Data through cookies, server logs, and similar technologies.')}</li>
                    <li><strong>{t('Third Parties or Publicly Available Sources')}:</strong> {t('We may receive personal data from third-party content providers using our Services.')}</li>
                </ul>
                <h3>{t('How We Use Your Personal Data')}</h3>
                <p>{t('We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:')}</p>
                <ul>
                    <li><strong>{t('Where we need to perform the contract we are about to enter into or have entered into with you.')}</strong></li>
                    <li><strong>{t('Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.')}</strong></li>
                    <li><strong>{t('Where we need to comply with a legal or regulatory obligation.')}</strong></li>
                </ul>
                <h3>{t('Disclosure of Your Personal Data')}</h3>
                <p>{t('We may have to share your personal data with the parties set out below for the purposes set out in the table in section 4 above.')}</p>
                <ul>
                    <li><strong>{t('Internal Third Parties')}:</strong> {t('Other companies in our group.')}</li>
                    <li><strong>{t('External Third Parties')}:</strong> {t('Service providers acting as processors based in the United States who provide IT and system administration services.')}</li>
                    <li><strong>{t('Third Parties to Whom We May Choose to Sell, Transfer, or Merge Parts of Our Business or Our Assets')}:</strong> {t('Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this Privacy Policy.')}</li>
                    <li><strong>{t('Third Parties to Whom We May Be Required to Disclose Your Personal Data to Comply with a Legal Obligation')}:</strong> {t('This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.')}</li>
                </ul>
                <h3>{t('International Data Transfer')}</h3>
                <p>{t('We do not transfer your personal data outside Algeria')}</p>
                <h3>{t('Data Retention')}</h3>
                <p>{t('We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.')}</p>
                <h3>{t('Securing Your Personal Information')}</h3>
                <p>{t('We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a secure environment.')}</p>
                <h3>{t('Changes to Privacy Policy and Your Duty to Inform Us of Changes')}</h3>
                <p>{t('We may update this Privacy Policy from time to time in our discretion and will notify you of any material changes to the way in which we treat personal information. When changes are made, we will revise the updated date at the top of this page. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Privacy Policy will be effective immediately upon the posting of the revised Privacy Policy unless otherwise specified. Your continued use of the Services after the effective date of the revised Privacy Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your personal data in a manner materially different than what was stated at the time your personal data was collected.')}</p>
                <h3>{t('Your Rights')}</h3>
                <p>{t('You have the right to:')}</p>
                <ul>
                    <li><strong>{t('Request access to your personal data.')}</strong> {t('This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.')}</li>
                    <li><strong>{t('Request correction of the personal data that we hold about you.')}</strong> {t('This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.')}</li>
                    <li><strong>{t('Request erasure of your personal data.')}</strong> {t('This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully, or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.')}</li>
                    <li><strong>{t('Object to processing of your personal data.')}</strong> {t('This enables you to object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.')}</li>
                    <li><strong>{t('Request restriction of processing of your personal data.')}</strong> {t('This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.')}</li>
                    <li><strong>{t('Request the transfer of your personal data to you or to a third party.')}</strong> {t('We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.')}</li>
                    <li><strong>{t('Withdraw consent at any time where we are relying on consent to process your personal data.')}</strong> {t('However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.')}</li>
                </ul>
                <h3>{t('Third-Party Services')}</h3>
                <p>{t('Our Services may contain links to third-party websites and applications. Any access to and use of such linked websites and applications is not governed by this Privacy Policy but instead is governed by the privacy policies of those third parties. We are not responsible for the information practices of such third-party websites or applications.')}</p>
                <h3>{t('Cookies')}</h3>
                <p>{t('You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.')}</p>
                <h3>{t('Contact Information')}</h3>
                <p>{t('For any inquiries or requests related to your personal data, please contact us at')} <a href="mailto:contact@SofizPay.com">contact@SofizPay.com</a>.</p>
                <p>{t('By using SofizPay, you agree to the terms outlined in this Privacy Policy.')}</p>
                <p>{t('Thank you for choosing SofizPay!')}</p>
            </div>
        </>
    );
};

export default TermEtConditions;
