import React from 'react';
import "./BreadcrumbsForFooter.css"
import { useParams, Navigate } from 'react-router-dom';
import pagesData from '../../../assets/Data/Footer.json';
import { useTranslation } from 'react-i18next';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
const BreadcrumbsForFooter = () => {
  const { nomFooter } = useParams();
  const { t } = useTranslation();
  if(nomFooter ==="Transparency"){
    return <Navigate to="/Footer/Transparency" />;
  }
  const pageData = pagesData[nomFooter];
  new WOW().init();

 if (!pageData ) {
    return <Navigate to="/error" />;
  }

  return (
    <div className="breadcrumbsFooter">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 col-md-12 col-12">
            <div className="breadcrumbs-content fadeInLeft wow">
              <h1 className="page-title"> {t(pageData.title)}</h1>
              <ul className="breadcrumb-nav">
                <li><a href="#footer">{t(pageData.title)}</a></li>
                <li>{t(pageData.type)}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default BreadcrumbsForFooter;
