import React from 'react';
import WOW from 'wow.js';
import './Faq.css'
import { useTranslation } from 'react-i18next';

const faqData = [
    {
        question: "Question 1",
        answer: "SofizPay answer"
      },
    {
      question: "Question 2",
      answer: "SofizPay answer2"
    },
    {
      question: "Question 3",
      answer: "SofizPay answer3"
    },
    {
      question: "Question 4",
      answer: "SofizPay answer4"
    },
    {
      question: "Question 5",
      answer: "SofizPay answer5"
    },
    {
      question: "Question 6",
      answer: "SofizPay answer6"
    }
    // إضافة المزيد من الأسئلة والإجابات حسب الحاجة
  ];
  
  const Faq = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    new WOW().init();
  
    return (
      <section id="faq" className="ud-faq" style={isArabic ? {  direction: 'rtl' } : {}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title-Faq text-center mx-auto">
                <span className="wow zoomIn">{t("FAQ")}</span>
                <h2 className="wow fadeInUp">{t("Any Questions? Answered")}</h2>
                <p className="wow fadeInUp">
                  {t("If you do not find the answer to your question here, do not hesitate to contact our customer service")}
                </p>
                <br />
              </div>
            </div>
          </div>
  
          <div className="row">
            <div className="col-lg-6">
              {faqData.slice(0, faqData.length / 2).map((item, index) => (
                <div className="ud-single-faq wow fadeInUp" data-wow-delay={`.${index + 1}s`} key={index}>
                  <div className="accordion">
                    <button
                      className="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index}`} 
                    >
                      <span className="icon flex-shrink-0">
                        <i className="lni lni-chevron-down"></i>
                      </span>
                      <span style={isArabic ? { marginRight:"20px" } : {}}>{t(item.question)}</span>
                    </button>
                    <div id={`collapse-${index}`} className="accordion-collapse collapse">
                      <div className="ud-faq-body" style={isArabic ? { marginRight:"80px" } : {}}>
                        {t(item.answer)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
  
            <div className="col-lg-6">
              {faqData.slice(faqData.length / 2).map((item, index) => (
                <div className="ud-single-faq wow fadeInUp" data-wow-delay={`.${index + 1}s`} key={index}>
                  <div className="accordion">
                    <button
                      className="ud-faq-btn collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index + faqData.length / 2}`}
                    >
                      <span className="icon flex-shrink-0">
                        <i className="lni lni-chevron-down"></i>
                      </span>
                      <span style={isArabic ? { marginRight:"20px" ,textAlign:"right" } : {}}>{t(item.question)}</span>
                    </button>
                    <div id={`collapse-${index + faqData.length / 2}`} className="accordion-collapse collapse">
                      <div className="ud-faq-body" style={isArabic ? { marginRight:"80px" } : {}}>
                        {t(item.answer)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Faq;
