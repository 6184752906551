import React from 'react';
import "./contant.css";
import { useTranslation } from 'react-i18next';
import pagesData from '../../../assets/Data/Features.json'; // استيراد ملف JSON
import { useParams } from 'react-router-dom';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';

const ContantMultiSignature = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  const { nomFeatures } = useParams();
  new WOW().init();

  const pageData = pagesData[nomFeatures];
  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';

  return (
    <>
      <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>
        <h2>{t('unparalleled_security_title')}</h2>
        <p>{t('importance_of_security_and_control')}</p>

        <h3>{t('what_are_multi_signature_wallets')}</h3>
        <p>{t('digital_vault_description')}</p>

        <ol>
          <li>{t('wallet_creation')}</li>
          <li>{t('assign_signing_rights')}</li>
          <li>{t('transaction_signatures')}</li>
          <li>{t('critical_transfer_review')}</li>
        </ol>

        <h3>{t('benefits_of_multi_signature_wallets_title')}</h3>
        <ul>
          <li>{t('enhanced_security')}</li>
          <li>{t('fraud_prevention')}</li>
          <li>{t('improved_accountability')}</li>
          <li>{t('streamlined_workflows')}</li>
        </ul>

        <p>{t('peace_of_mind_title')}</p>

        <ul>
          <li>{t('reduced_risk')}</li>
          <li>{t('increased_trust')}</li>
          <li>{t('compliance_assurance')}</li>
        </ul>

        <h3>{t('empowering_businesses_title')}</h3>

        <p>{t('multi_signature_wallet_feature')}</p>

      </div>
    </>
  );
};

export default ContantMultiSignature;
