import React from 'react';
import "./contant.css";
import { useTranslation } from 'react-i18next';
import pagesData from '../../../assets/Data/Features.json'; // استيراد ملف JSON
import { useParams } from 'react-router-dom';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';

const ContantPowerfulSDK = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  const { nomFeatures } = useParams();
  new WOW().init();

  const pageData = pagesData[nomFeatures]
  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';
  return (
    <>
      <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>

        <p>
          <div className="theme-doc-markdown markdown">

            <h2 className="anchor anchorWithStickyNavbar_LWe7" id="sdk-library">{t('SDK library')}</h2>
            <p>{t('Interact with the Stellar network using the SDK in your preferred language. The JavaScript, Java, and Go SDKs are maintained by SDF and the rest are maintained by dedicated community developers. All SDKs are open-source; file a GitHub issue or pull request in the specific SDK repository if you have questions or suggestions.')}</p>
            <p>{t('Each SDK has its own source code and documentation. Learn how to use a specific SDK by referring to the documentation- most docs offer practical examples that demonstrate how to construct and submit transactions and interact with Horizon endpoints.')}</p>
            <p>{t('to integrate SofizPay use the asset : DZT')}</p>
            <p style={{wordWrap:'break-word'}}>{t('issuer: GCAZI7YBLIDJWIVEL7ETNAZGPP3LC24NO6KAOBWZHUERXQ7M5BC52DLV')}</p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="javascript">{t('JavaScript')}</h3>
            <p><a href="https://github.com/stellar/js-stellar-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://stellar.github.io/js-stellar-sdk/" target="_blank" rel="noopener noreferrer">{t('Docs')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="java">{t('Java')}</h3>
            <p><a href="https://github.com/stellar/java-stellar-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://stellar.github.io/java-stellar-sdk/" target="_blank" rel="noopener noreferrer">{t('Docs')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="go">{t('Go')}</h3>
            <p>{t('This SDK is split up into separate packages, all of which you can find in the')} <a
              href="https://github.com/stellar/go/blob/master/docs/reference/readme.md" target="_blank"
              rel="noopener noreferrer">{t('Go monorepo README')}</a>. {t('The two key libraries for interacting with Horizon are')}  
              <code> txnbuild </code>, {t('which enables the construction, signing, and encoding of Stellar transactions, and')} <code> horizonclient </code>, {t('which provides a web client for interfacing with Horizon server REST endpoints to retrieve ledger information and submit transactions built with')} <code> txnbuild </code>.</p>
            <ul className="">
              <li>{t('txnbuild')}: <a href="https://github.com/stellar/go/tree/master/txnbuild" target="_blank"
                rel="noopener noreferrer">{t('Source')}</a> | <a href="https://godoc.org/github.com/stellar/go/txnbuild"
                  target="_blank" rel="noopener noreferrer">{t('Docs')}</a></li>
              <li>{t('Horizonclient')}: <a href="https://github.com/stellar/go/tree/master/clients/horizonclient" target="_blank"
                rel="noopener noreferrer">{t('Source')}</a> | <a
                  href="https://godoc.org/github.com/stellar/go/clients/horizonclient" target="_blank"
                  rel="noopener noreferrer">{t('Docs')}</a></li>
            </ul>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="python">{t('Python')}</h3>
            <p><a href="https://github.com/StellarCN/py-stellar-base" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://stellar-sdk.readthedocs.io/en/latest/" target="_blank" rel="noopener noreferrer">{t('Docs')}</a> | <a
                href="https://github.com/StellarCN/py-stellar-base/tree/master/examples" target="_blank"
                rel="noopener noreferrer">{t('Examples')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="c-net">{t('C# .NET')}</h3>
            <p><a href="https://github.com/elucidsoft/dotnet-stellar-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> |
              <a href="https://elucidsoft.github.io/dotnet-stellar-sdk/api/index.html" target="_blank"
                rel="noopener noreferrer">{t('Docs')}</a> | <a
                  href="https://elucidsoft.github.io/dotnet-stellar-sdk/tutorials/index.html" target="_blank"
                  rel="noopener noreferrer">{t('Tutorials')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="ruby">{t('Ruby')}</h3>
            <p><a href="https://github.com/astroband/ruby-stellar-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://github.com/astroband/ruby-stellar-sdk/blob/master/base/README.md" target="_blank"
              rel="noopener noreferrer">{t('Base Source')}</a> | <a
                href="https://github.com/astroband/ruby-stellar-sdk/blob/master/sdk/README.md" target="_blank"
                rel="noopener noreferrer">{t('SDK Source')}</a> | <a href="https://www.rubydoc.info/gems/stellar-sdk"
                  target="_blank" rel="noopener noreferrer">{t('Docs')}</a> | <a
                    href="https://github.com/astroband/ruby-stellar-sdk/tree/master/base/examples" target="_blank"
                    rel="noopener noreferrer">{t('Base Examples')}</a> | <a
                      href="https://github.com/astroband/ruby-stellar-sdk/tree/master/sdk/examples" target="_blank"
                      rel="noopener noreferrer">{t('SDK Examples')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="ios">{t('iOS')}</h3>
            <p><a href="https://github.com/Soneso/stellar-ios-mac-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://github.com/Soneso/stellar-ios-mac-sdk/tree/master/docs" target="_blank"
              rel="noopener noreferrer">{t('Docs')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="scala">{t('Scala')}</h3>
            <p><a href="https://github.com/Synesso/scala-stellar-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://synesso.github.io/scala-stellar-sdk/" target="_blank" rel="noopener noreferrer">{t('Docs')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="qtc">{t('Qt/C++')}</h3>
            <p><a href="https://github.com/bnogalm/StellarQtSDK" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://github.com/bnogalm/StellarQtSDK/wiki" target="_blank" rel="noopener noreferrer">{t('Docs')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="flutter">{t('Flutter')}</h3>
            <p><a href="https://github.com/Soneso/stellar_flutter_sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://github.com/Soneso/stellar_flutter_sdk/tree/master/documentation" target="_blank"
              rel="noopener noreferrer">{t('Docs')}</a> | <a
                href="https://github.com/Soneso/stellar_flutter_sdk/tree/master/documentation/sdk_examples" target="_blank"
                rel="noopener noreferrer">{t('Examples')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="php">{t('PHP')}</h3>
            <p><a href="https://github.com/Soneso/stellar-php-sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://github.com/Soneso/stellar-php-sdk#installation" target="_blank"
              rel="noopener noreferrer">{t('Docs')}</a> | <a href="https://github.com/Soneso/stellar-php-sdk/tree/main/examples"
                target="_blank" rel="noopener noreferrer">{t('Examples')}</a></p>
            <h3 className="anchor anchorWithStickyNavbar_LWe7" id="elixir-beta">{t('Elixir (beta)')}</h3>
            <p><a href="https://github.com/kommitters/stellar_sdk" target="_blank" rel="noopener noreferrer">{t('Source')}</a> | <a
              href="https://hexdocs.pm/stellar_sdk/readme.html" target="_blank" rel="noopener noreferrer">{t('Docs')}</a> | <a
                href="https://github.com/kommitters/stellar_sdk/blob/main/docs" target="_blank"
                rel="noopener noreferrer">{t('Examples')}</a></p>
          </div>
        </p>
      </div>
    </>
  );
};

export default ContantPowerfulSDK;
