import React from 'react';
import Preloader from "./components/Preloader/Preloader";
import Header from "./components/Header/Header";
import HeroArea from "./components/HeroArea/HeroArea";
import Features from "./components/Features/Features";
import IntroVideo from "./components/IntroVedio/IntroVedio";
import Faq from "./components/Faq/Faq";
import Testimonial from "./components/Testimonials/Testimonials";
import Contact from "./components/Contact/Contact";
import Brands from "./components/Brands/Brands";
import Footer from "./components/Footer/Footer";
import PressMedia from './components/Press&Media/PressMedia';
function Home() {
  return (
    <>
      <Preloader />
      <Header />
      <HeroArea />
      <Features />
      <IntroVideo />
      {/* <Testimonial /> */}
      <Faq />
      <Brands />
      <PressMedia/>
      <Contact />
      <Footer />
    </>
  );
}
export default Home;
