import React from 'react';
import "./contant.css";
import { useTranslation } from 'react-i18next';
import pagesData from '../../../assets/Data/Features.json'; // استيراد ملف JSON
import { useParams } from 'react-router-dom';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';

const ContantInterbankTransaction = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  const { nomFeatures } = useParams();
  new WOW().init();

  const pageData = pagesData[nomFeatures];
  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';

  return (
    <>
      <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>
        <h2>{t('seamless_bank_transfers_title')}</h2>
        <p>{t('beyond_in_app_transactions')}</p>

        <ul>
          <li>{t('transfer_dzt_tokens')}</li>
          <ul>
            <li>{t('visiting_banks')}</li>
            <li>{t('complex_procedures')}</li>
            <li>{t('hidden_fees')}</li>
          </ul>
        </ul>

        <p>{t('trusted_intermediary')}</p>

        <ul>
          <li>{t('security_first')}</li>
          <li>{t('transparency_throughout')}</li>
          <li>{t('effortless_conversion')}</li>
        </ul>

        <p>{t('interbank_transfers_benefits_title')}</p>

        <ul>
          <li>{t('convenience')}</li>
          <li>{t('speed')}</li>
          <li>{t('security')}</li>
        </ul>

        <h3>{t('new_era_of_financial_transactions')}</h3>

        <p>{t('new_era_description')}</p>

        <p>{t('join_the_future')}</p>
      </div>
    </>
  );
};

export default ContantInterbankTransaction;
