import React from 'react';
import "./contant.css";
import { useTranslation } from 'react-i18next';
import pagesData from '../../../assets/Data/Features.json'; // استيراد ملف JSON
import { useParams } from 'react-router-dom';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
const ContantSecurity = () => {
  // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  const { nomFeatures } = useParams();
  new WOW().init();

  const pageData = pagesData[nomFeatures]
  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';
  return (
    <>
      <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>
        <h2>{t('unmatched_security')}</h2>
        <p>{t('importance_of_trust_and_security')}</p>
        <h3>{t('what_makes_so_secure')}</h3>
        <p>{t('public_ledger_description')}</p>
        <ul>
          <li>{t('immutable_description')}</li>
          <li>{t('transparent_description')}</li>
        </ul>
        <h3>{t('power_of_decentralization')}</h3>
        <p>{t('distributed_network')}</p>
        <ul>
          <li>{t('verify_transactions')}</li>
          <li>{t('maintain_network')}</li>
        </ul>
        <h3>{t('your_control')}</h3>
        <p>{t('user_control')}</p>
        <ul>
          <li>{t('you_hold_the_key')}</li>
          <li>{t('enhanced_privacy')}</li>
        </ul>
        <h3>{t('security_beyond_network')}</h3>
        <p>{t('beyond_network_security')}</p>
        <ul>
          <li>{t('industry_security_practices')}</li>
          <li>{t('mobile_app_security')}</li>
        </ul>
        <p>{t('conclusion')}</p>
        </div>
    </>
  );
};

export default ContantSecurity;
