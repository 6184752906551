import Preloader from "../Home/components/Preloader/Preloader";
import ErrorPage from "./ErrorPage/ErrorPage";


function NotFound() {
  return (
<>
<Preloader/>
<ErrorPage/>
</>
  );
}

export default NotFound;
