import React from 'react';
import "./TransactionTable.css";
import { useTranslation } from 'react-i18next';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
function TransactionTable() {
    const handleDownload = (fileUrl) => {
        // Logic to handle file download goes here
        console.log(`Downloading file: ${fileUrl}`);
        window.open(fileUrl, '_blank');
    };
    const { t} = useTranslation();
    new WOW().init();

    const data = {
        headers: ["Date", "File"],
        rows: [
            [ "https://res.cloudinary.com/dfu8t8dos/image/upload/v1695071726/abwsnsko7d9v5kqiwrwk.pdf","2024-02-10"],
        ],
    };

    return (
        <div className='TransactionTable'>
                 <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ud-section-title text-center mx-auto">
                            <h2 className='fadeInUp wow'>{t("Proof of reserves")}</h2>
                            <p className='fadeInLeft wow' data-wow-delay=".1s">
                                {t("Service support reserve manuals will be published periodically to ensure transparency")}
                            </p>
                        </div>
                    </div>
                </div>
                </div>

            <div className='tableContant'>
                <table className="StyledTable">
                    <thead>
                        <tr>
                            <th>{data.headers[0]}</th>
                            <th>{data.headers[1]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.rows.map((row, index) => (
                            <tr key={index}>
                                <td>{row[1]}</td>
                                <td>
                                    <button onClick={() => handleDownload(row[0])}>
                                        <i className="lni lni-download"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TransactionTable;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import "./TransactionTable.css";
// import { useTranslation } from 'react-i18next';
// import WOW from 'wow.js';
// import 'wow.js/css/libs/animate.css';

// function TransactionTable() {
//     const [transactionData, setTransactionData] = useState([]);
//     const { t } = useTranslation();

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get('YOUR_API_ENDPOINT');
//                 setTransactionData(response.data);
//             } catch (error) {
//                 console.error('Error fetching transaction data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     const handleDownload = (fileUrl) => {
//         console.log(`Downloading file: ${fileUrl}`);
//         window.open(fileUrl, '_blank');
//     };

//     new WOW().init();

//     return (
//         <div className='TransactionTable'>
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="ud-section-title text-center mx-auto">
//                             <h2 className='fadeInUp wow'>{t("Proof of reserves")}</h2>
//                             <p className='fadeInLeft wow' data-wow-delay=".1s">
//                                 {t("There are many variations...")}
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className='tableContant'>
//                 <table className="StyledTable">
//                     <thead>
//                         <tr>
//                             <th>Date</th>
//                             <th>File</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {transactionData.map((transaction, index) => (
//                             <tr key={index}>
//                                 <td>{transaction.date}</td>
//                                 <td>
//                                     <button onClick={() => handleDownload(transaction.file)}>
//                                         <i className="lni lni-download"></i>
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// }

// export default TransactionTable;
