import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import "./Features.css";
import WOW from 'wow.js';
import { useTranslation } from 'react-i18next';
import featuresData from '../../../../assets/Data/Features.json';
import security from '../../../../assets/images/features/security.png';
import signature from '../../../../assets/images/features/signature.png';
import send_money from '../../../../assets/images/features/send_money.png';
import sdk from '../../../../assets/images/features/sdk.png';
import qr_code_Scanner from '../../../../assets/images/features/qr_code_scanner.png';
import lightbulb from '../../../../assets/images/features/lightbulb.png';

// Map image keys to imports
const images = {
  security,
  signature,
  send_money,
  sdk,
  qr_code_Scanner,
  lightbulb,
  // Add other images here
};

const Features = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // Use useNavigate hook to access navigation functionality
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  new WOW().init();
  const isArabic = i18n.language === 'ar';
  const features = Object.keys(featuresData); // Use Object.keys() to get an array of keys

  // Function to handle button click and navigate to the feature page
  const handleClick = (featureKey) => {
    navigate(`/Features/${featureKey}`); // Use navigate to navigate to the specified feature page
  };

  return (
    <section id="features" className="features section" style={isArabic ? {  direction: 'rtl' } : {}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title-Features  mx-auto text-center">
              <span className="wow zoomIn"> {t("Features")}</span>
              <h2 className="wow fadeInUp">{t("We're always innovating to meet your evolving needs")}</h2>
              <p className="wow fadeInUp">
                {t("Your perfect partner in the world of electronic payments, SofizPay")}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {(showAllFeatures ? features : features.slice(0, 6)).map((featureKey, index) => {
            const feature = featuresData[featureKey];
            return (
              <div key={index} className="col-lg-4 col-md-6 col-12">
                <div className="single-feature wow fadeInUp " data-wow-delay={(index + 1) * 0.2 + 's'} style={isArabic ? { textAlign: `Right `, background: ` radial-gradient(at left top, ${feature.color1}, transparent), radial-gradient(at right top, ${feature.color2}, transparent), radial-gradient(at left bottom, ${feature.color2}, #0000003b)` } : { background: ` radial-gradient(at left top, ${feature.color1}, transparent), radial-gradient(at right top, ${feature.color2}, transparent), radial-gradient(at left bottom, ${feature.color2}, #0000003b)` }}>
                    <img src={images[feature.icon]} class="material-symbols-outlined" style={{maxWidth:"50px"}} alt="Feature Icon" />
                  <h3 style={{height:"40px"}}>{t(feature.title)}</h3>
                  <p style={{height:"70px"}}>{t(feature.description)}</p>
                  <button className="btn" onClick={() => handleClick(featureKey)}>
                    {t("more")}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="row">
          {!showAllFeatures && (
            <div className="col-12 wow fadeInUp" data-wow-delay={3 * 0.2 + 's'} style={{ justifyContent: 'center', display: 'flex', alignItems: "center" }}>
              <button className="btn" onClick={() => setShowAllFeatures(true)}>
                {t("more Features")}
              </button>
            </div>
          )}
        </div> */}
      </div>
    </section>
  );
};

export default Features;
