import React from 'react';
import Logo from '../../../../assets/images/logo/white-logo copy.png';
import "./Footer.css"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
    const { t, i18n } = useTranslation();
    // التحقق إذا كانت لغة الصفحة هي العربية
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();

    const handleClick = (featureKey) => {
        navigate(`${featureKey}`);
    };
  
    


    return (
        // style={{ direction: 'rtl' }}
        <footer className="footer" style={isArabic ? { direction: 'rtl' } : {}}>
            {/* Start Footer Top */}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-12">
                            {/* Single Widget */}
                            <div className="single-footer f-about">
                                <div className="logo">
                                    <a href="#home">
                                        <img src={Logo} alt="Logo" />
                                    </a>
                                </div>
                                <p>{t("Leading the digital revolution.")}</p>
                                <ul className="social">
                                    <li>
                                        <a href="https://www.facebook.com/SofizPay">
                                            <i className="lni lni-facebook-filled"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/SofizPay">
                                            <i className="lni lni-twitter-original"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/SofizPay">
                                            <i className="lni lni-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/SofizPay">
                                            <i className="lni lni-linkedin-original"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/@SofizPay" style={isArabic ? { marginRight: "20px" } : {}}>
                                            <i className="lni lni-youtube"></i>
                                        </a>
                                    </li>

                                </ul>
                                <p className="copyright-text">

                                    {t("Copyright © 2024 EURL SofizPay")}
                                </p>
                            </div>
                        </div>
                        {/* End Single Widget */}

                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    {/* Single Widget */}
                                    <div className={`single-footer f-link ${isArabic ? "f-linkArabic" : ""}`}  >
                                        <h3>{t("Solutions")}</h3>
                                        <ul >
                                            <li><button >{t("SofizPay app")}</button></li>
                                            <li><button  onClick={() => handleClick("/Features/PowerfulSDK")}>{t("SDK")}</button></li>
                                            <li><a href='https://drive.google.com/file/d/18o7WxTJeGbKM0NMqOeCC-Yf1xTdGNSKR/view?fbclid=IwAR2SiDisnbAbckzQmRd8_5DfZXogTxGvrbyU2ZyERxSobqGEfCd8KCyUxtw' >{t("Woocommerce integration")}</a></li>
                                            <li><button >{t("Edahabiya/CIB API")}</button></li>
                                            <li><button >{t("IT development services")}</button></li>
                                        </ul>
                                    </div>
                                    {/* End Single Widget */}
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    {/* Single Widget */}
                                    <div className={`single-footer f-link ${isArabic ? "f-linkArabic" : ""}`}  >
                                        <h3>{t("Support")}</h3>
                                        <ul>
                                            <li><button onClick={() => handleClick("/Support/Fees")} >{t("Fees")}</button></li>
                                            <li><a  className="a" href='#contact'>{t("Contact Us")}</a></li>

                                        </ul>
                                    </div>
                                    {/* End Single Widget */}
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    {/* Single Widget */}
                                    <div className={`single-footer f-link ${isArabic ? "f-linkArabic" : ""}`}  >
                                        <h3>{t("Company")}</h3>
                                        <ul>
                                            <li><a href='#Brands' >{t("Partners")}</a></li>
                                            <li><a href='#Media' >{t("Press")}</a></li>
                                        </ul>
                                    </div>
                                    {/* End Sigle Widget */}
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    {/* Single Widget */}
                                    <div className={`single-footer f-link ${isArabic ? "f-linkArabic" : ""}`}  >
                                        <h3>{t("Legal")}</h3>
                                        <ul>
                                            <li><button onClick={() => handleClick("/Legal/TermEtConditions")} >{t("Terms & Conditions")}</button></li>
                                            <li><button onClick={() => handleClick("/Legal/PrivacyPolicy")}>{t("Privacy Policy")}</button></li>
                                            <li><button onClick={() => handleClick("/Legal/Transparency")}>{t("Transparency")}</button></li>
                                        </ul>
                                    </div>
                                    {/* End Single Widget */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ End Footer Top */}
        </footer>
    );
};

export default Footer;
