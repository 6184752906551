import React from 'react';
import '../../../css/bootstrap.min.css';
import photo5 from '../../../assets/images/logo/qr.png';
import photo2 from '../../../assets/images/logo/gie monitique.png';
import photo3 from '../../../assets/images/logo/label.png';
import photo4 from '../../../assets/images/logo/satim.png';
import './imageSlider.css';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function ImageSlider() {
  const items = [
    { id: 5, image: photo5 },
    { id: 2, image: photo2 },
    { id: 3, image: photo3 },
    { id: 4, image: photo4 },
  ];

  new WOW().init();
  const { t } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <br />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title text-center mx-auto">
                <h2 className="fadeInUp wow">{t('Legal documents')}</h2>
              </div>
            </div>
          </div>
        </div>

        <Carousel responsive={responsive}>
          {items.map(item => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={item.id}>
              <img src={item.image} alt={`Item ${item.id}`} />
            </div>
          ))}
        </Carousel>
      <br />
    </> 
  );
}

export default ImageSlider;
