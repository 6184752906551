import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css'; // Import the animate.css for WOW.js animations
import "./IntroVedio.css"
import { useTranslation } from 'react-i18next';


const IntroVideo = () => {
    const { t, i18n } = useTranslation();
    // Initialize WOW.js for animations
    new WOW().init();
    const isArabic = i18n.language === 'ar';
    return (
        <section id="Intro"className="intro-video-area section"style={isArabic ? { direction: 'rtl' } : {}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="inner-content-head">
                            <div className="inner-content">
                                <div className="ud-section-title-InfroVedio text-center mx-auto">
                                    <span className="wow zoomIn" data-wow-delay=".2s">
                                        {t("Intro Vedio")}
                                    </span>
                                    <h2 className="wow fadeInUp" data-wow-delay=".4s">
                                    {t("Looking for a seamless and secure online payment experience?")}
                                    </h2>
                                    <p className="wow fadeInUp" data-wow-delay=".6s">
                                    {t("Would you like to know more about SofizPay? Click on the button below to learn more.")}
                                    </p>
                                </div>
                                <div className="intro-video-play">
                                    <div className="play-thumb wow zoomIn" data-wow-delay=".2s">
                                        <a
                                            href="https://www.youtube.com/@SofizPay"
                                            className="glightbox video"
                                        >
                                            <i className="lni lni-play"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IntroVideo;
