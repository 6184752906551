import React from 'react';
import "./contant.css";
import { useTranslation } from 'react-i18next';
import pagesData from '../../../assets/Data/Features.json'; // استيراد ملف JSON
import { useParams } from 'react-router-dom';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';

const ContantEfficiency = () => {
  const { t, i18n } = useTranslation();
  const { nomFeatures } = useParams();
  new WOW().init();

  const isArabic = i18n.language === 'ar';
  
  return (
    <>
      <div className='mycard footere' style={isArabic ? { direction: 'rtl' } : {}}>
        <h2>{t('faster_than_cash_title')}</h2>
        <p>{t('time_is_precious')}</p>

        <h3>{t('goodbye_waiting_lines')}</h3>
        <p>{t('no_more_traditional_banking')}</p>

        <h3>{t('how_it_works_title')}</h3>
        <ul>
          <li>{t('send_receive_dzt_tokens')}</li>
          <li>{t('qr_code_payments')}</li>
          <li>{t('instant_money_transfer')}</li>
        </ul>

        <h3>{t('stellar_network_title')}</h3>
        <ul>
        <li>{t('stellar_network_description')}</li>

        <li>{t('everyday_life_translation')}</li>
        <li>{t('effortless_transactions')}</li>
        </ul>
        <h3>{t('beyond_speed_title')}</h3>
        <ul>
          <li>{t('reduced_transaction_costs')}</li>
          <li>{t('availability_24_7')}</li>
          <li>{t('simplified_record_keeping')}</li>
        </ul>

        <p>{t('SofizPay_empowers_you_with')}</p>
        <ul>
          <li>{t('instantaneous_transactions')}</li>
          <li>{t('reduced_costs')}</li>
          <li>{t('convenience_and_accessibility')}</li>
        </ul>

        <p>{t('experience_future_of_payments')}</p>
      </div>
    </>
  );
};

export default ContantEfficiency;
