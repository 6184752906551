import React, { useEffect } from 'react';

import Header2 from '../../FeatruesPages/Header/Header';
import Footer2 from '../../FeatruesPages/Footer/Footer';
import Preloader from '../../Home/components/Preloader/Preloader';
import BreadForFess from './BreadcrumbsForProofOfReserves/BreadForFess';
import FeesTable from './Tables/FeesTable';

function Fees() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('Scrolled to top');
    }, 0);
  }, []);
  return (
    <>
<Preloader/>
<Header2/>
<BreadForFess/>
<FeesTable/>
<Footer2/>
    </>
  );
}
export default Fees;
