import React, { useEffect } from 'react';

import Header2 from '../FeatruesPages/Header/Header';
import Footer2 from '../FeatruesPages/Footer/Footer';
import Preloader from '../Home/components/Preloader/Preloader';
import BreadcrumbsForFooter from './BreadcrumbsForFooter/BreadcrumbsForFooter';
import ContantForFooter from './ContantForFooter/ContantForFooter';
import Innovation from './ContantForFooter/Innovation';
import TermEtConditions from './ContantForFooter/TermEtConditions';
import PrivacyPolicy from './ContantForFooter/PrivacyPolicy';
import { useParams, useNavigate } from 'react-router-dom';

function FooterPages() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('Scrolled to top');
    }, 0);
  }, []);
  const navigate = useNavigate();

  // تحديد الجزء المطلوب بناءً على القيمة
  const { nomFooter } = useParams();

  let ContentComponent;
  switch (nomFooter) {
    case 'Innovation':
      ContentComponent = Innovation;
      break;
    case 'TermEtConditions':
      ContentComponent = TermEtConditions;
      break;
    case 'PrivacyPolicy':
      ContentComponent = PrivacyPolicy;
      break;

    default:
      navigate('/error');
      return null;
  }
  return (
    <>
      <Preloader />
      <Header2 />
      <BreadcrumbsForFooter />
      <ContentComponent />
      <Footer2 />
    </>
  );
}
export default FooterPages;
