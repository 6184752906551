import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dataArabic from "./assets/Language/ar.json";
import dataEnglish from "./assets/Language/en.json";
import dataFranch from "./assets/Language/fr.json";

const resources = {
  ar: {
    translation: dataArabic,
  },
  en: {
    translation: dataEnglish,
  },
  fr: {
    translation: dataFranch,
  },
};

const storedLanguage = localStorage.getItem("language");

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: storedLanguage || "fr", 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
