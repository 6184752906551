import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Preloader from '../Preloader/Preloader';

const LanguageSelector = ({ handleLanguageChange, currentLanguage }) => {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [showPreloader, setShowPreloader] = useState(false);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    // يُحدث عند تغيير لغة المستخدم
    useEffect(() => {
        // تحديث اللغة المعروضة في القائمة المنسدلة بناءً على لغة المستخدم الحالية
        if (currentLanguage === 'ar') {
            setSelectedLanguage(t('Arabic'));
        } else if (currentLanguage === 'en') {
            setSelectedLanguage(t('English'));
        } else if (currentLanguage === 'fr') {
            setSelectedLanguage(t('French'));
        }
    }, [currentLanguage]);

    // دالة تُستدعى عند تغيير لغة المستخدم
    const handleChange = (language) => {
        // حفظ لغة المستخدم في localStorage للاحتفاظ بها
        localStorage.setItem("language", language);
        setShowPreloader(true);
        setSelectedLanguage(language);
        // تحديث لغة التطبيق الرئيسية
        handleLanguageChange(language);
    };

    // يُحدث عندما يتم إظهار Preloader
    useEffect(() => {
        let timeout;
        if (showPreloader) {
            // تأخير لإظهار Preloader لفترة زمنية
            timeout = setTimeout(() => {
                setShowPreloader(false);
            }, 2000);
        }

        // إلغاء تأثير تأخير Preloader عند تغيير أو إخفاء Preloader
        return () => {
            clearTimeout(timeout);
        };
    }, [showPreloader]);

    return (
        <ul id="nav" className="navbar-nav ms-auto" style={isArabic ? { direction: "ltr" } : { direction: "ltr" }}>
            <li className="nav-item">
                <a className={`${isArabic ? 'dde-menu' : 'dd-menu'} collapsed`} href="/" data-bs-toggle="collapse"
                    data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation" style={isArabic ? { textAlign: "right",direction:"rtl" } : {}}> {selectedLanguage}</a>
                <ul className="sub-menu collapse " id="submenu-1-4">
                    {/* روابط لتغيير لغة التطبيق */}
                    {(currentLanguage !== 'ar') && (
                        <li className={`nav-item ${isArabic ? 'li-language' : ''}`} style={isArabic ? { textAlign: "right" } : {}}>
                            <a href="#" onClick={() => handleChange('ar')}>{t('Arabic')}</a>
                        </li>
                    )}
                    {(currentLanguage !== 'fr') && (
                        <li className={`nav-item ${isArabic ? 'li-language' : ''}`}>
                            <a href="#" onClick={() => handleChange('fr')}>{t('French')}</a>
                        </li>
                    )}
                    {(currentLanguage !== 'en') && (
                        <li className={`nav-item ${isArabic ? 'li-language' : ''}`}>
                            <a href="#" onClick={() => handleChange('en')}>{t('English')}</a>
                        </li>
                    )}
                    {/* عرض Preloader عند الحاجة */}
                    {showPreloader && <Preloader />}
                </ul>
            </li>
        </ul>
    );
};

export default LanguageSelector;
