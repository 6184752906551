import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import './css/animate.css';
import './css/bootstrap.min.css';
import './css/glightbox.min.css';
import './css/LineIcons.2.0.css';
import './css/main.css';
import './css/tiny-slider.css';
import FooterPages from "./Pages/FooterPages/FooterPages";
import Transparency from "./Pages/FooterPages/Transparency/Transparency";
import FeatruesPages from "./Pages/FeatruesPages/FeatruesPages";
import Fees from "./Pages/FooterPages/Fees/Fees";

import PageApp from "./Pages/Home/PageApp";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Features/:nomFeatures" element={<FeatruesPages />} />
        <Route path="/legal/:nomFooter" element={<FooterPages />} />
        <Route path="/legal/Transparency" element={<Transparency />} />
        <Route path="/Support/Fees" element={<Fees />} />
        <Route path="/App/:nomPage" element={<PageApp />} />
        <Route path="/error" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
