import React, { useState, useEffect } from 'react';
import './Preloader.css';
import logo from '../../../../assets/images/logo/logo.5e0e42a31c36.png';

const Preloader = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const fadeout = () => {
      setVisible(false);
    };

    const timeoutId = window.setTimeout(fadeout, 800);

    return () => {
      // Clear the timeout to avoid calling fadeout when the component is unmounted
      window.clearTimeout(timeoutId);
    };
  }, []);

  return visible ? (
    <div className="preloader">
      <div className="preloader-inner">
        <img src={logo}  className="rotate-image" />
      </div>
    </div>
  ) : null;
};

export default Preloader;
