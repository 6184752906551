import { useTranslation } from "react-i18next";
import "./ErrorPage.css"

function ErrorPage() {
      // استخدام هوك اللغة للوصول إلى الدوال والحالة المرتبطة بالترجمة
  const { t, i18n } = useTranslation();
  // التحقق إذا كانت لغة الصفحة هي العربية
  const isArabic = i18n.language === 'ar';
    return (
        <>
            <div class="error-area" style={isArabic ? { fontFamily: "Noto Sans Arabic,sans-serif", direction: 'rtl' } : {}}>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="error-content">
                                <h1>{t("404")}</h1>
                                <h2>{t("Oops! Page Not Found!")}</h2>
                                <p>{t("The page you are looking for does not exist. It might have been moved or deleted.")}</p>
                                <div class="button">
                                    <a href="/" class="ud-main-btn">{t("Back to Home")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ErrorPage;
