import React, { useEffect } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import Header2 from '../FeatruesPages/Header/Header';
import Footer2 from '../FeatruesPages/Footer/Footer';
import Contact from './components/Contact/Contact';
import Faq from './components/Faq/Faq';
import Preloader from './components/Preloader/Preloader';
import BreadcrumbsForFooter from '../FooterPages/BreadcrumbsForFooter/BreadcrumbsForFooter';
import { useTranslation } from 'react-i18next';
function PageApp() {
    const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log('Scrolled to top');
    }, 0);
  }, []);
  const { nomPage } = useParams();
  let ContentComponent;
  switch (nomPage) {
    case 'ContactUs':
      ContentComponent = Contact;
      break;
    case 'FAQ':
      ContentComponent = Faq;
      break;

    default:
      navigate('/error');
      return null;
  }
  return (
    <>
      <Preloader />
      <Header2 />
      <div className="breadcrumbsFooter">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 col-md-12 col-12">
            <div className="breadcrumbs-content fadeInLeft wow">
              <h1 className="page-title"> {t(nomPage)}</h1>
              <ul className="breadcrumb-nav">
                <li><a href="#footer">{t(nomPage)}</a></li>
                <li>{t("app")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>      <ContentComponent/>
      <Footer2 />
    </>
  );
}
export default PageApp;
