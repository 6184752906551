import React from 'react';
import "./BreadcrumbsForProofOfReserves.css"
import { useTranslation } from 'react-i18next';
import WOW from 'wow.js';
import 'wow.js/css/libs/animate.css';
const BreadcrumbsForProofOfReserves = () => {
  const { t, i18n } = useTranslation();
  new WOW().init();

  return (
    <div className="breadcrumbsFooter">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-3 col-md-12 col-12">
            <div className="breadcrumbs-content fadeInLeft wow">
              <h1 className="page-title">{t("Fees")}</h1>
              <ul className="breadcrumb-nav">
                <li><a href="#footer">{t("Fees")}</a></li>
                <li>{t("Support")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadcrumbsForProofOfReserves;
