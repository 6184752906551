import React from 'react';
import WOW from 'wow.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ENTV from '../../../../assets/images/PressMedia/ENTV.png';
import Elnahar from '../../../../assets/images/PressMedia/alnahare.png';
import alchabab from '../../../../assets/images/PressMedia/alchabab.png';
import aljomhoria from '../../../../assets/images/PressMedia/aljomhoria.png';
import asdaeAlgharb from '../../../../assets/images/PressMedia/asdaeAlgharb.png';

import { useTranslation } from 'react-i18next';

import "./PressMedia.css"

const images = [
    { src: ENTV, link: 'https://www.entv.dz/' },
    { src: Elnahar, link: 'https://www.facebook.com/watch/?ref=search&v=890815562697558&external_log_id=ee455983-4384-4b1b-ba7f-8cb42c77e267&q=SofizPay%20elnahhare' },
    { src: alchabab, link: 'https://www.facebook.com/Chebabdz1/videos/1186831335625809' },
    { src: aljomhoria, link: 'https://www.eldjoumhouria.dz/article/22628/?fbclid=IwAR1yU0CxlNIZ_16qTyiRFP7BOE85F8X-f7FAZI_DspOQLtEDiaGwJNFZV1A' },
    { src: asdaeAlgharb, link: 'https://asdaalgharb.dz/' }
];

const PressMedia = () => {
    const { t, i18n } = useTranslation();

    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 430,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            }
        ],
    };

    new WOW().init();

    return (
        <section id="Media" className="ud-testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ud-section-title text-center mx-auto wow fadeInUp" data-wow-delay=".4s"style={{marginBottom:"80px"}}>
                            <h2>{t("Press&Media")}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ud-brands wow fadeInUp" data-wow-delay=".6s">
                            <Slider {...settings}>
                                {images.map((item, index) => (
                                    <div className="ud-brands-logo" key={index}>
                                        <div className="ud-single-logo">
                                            {/* Wrap img with anchor tag */}
                                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                <img src={item.src} alt={`Image ${index + 1}`} />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PressMedia;
