import React from 'react';
import WOW from 'wow.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AlgeriePoste from '../../../../assets/images/brands/AlgeriePoste.png';
import Carte_Ban from '../../../../assets/images/brands/Carte Ban.png';
import ooredoo from '../../../../assets/images/brands/ooredoo.png';
import mobilis from '../../../../assets/images/brands/mobilis.png';
import baridiMob from '../../../../assets/images/brands/baridiMob.png';
import djezzy from '../../../../assets/images/brands/djezzy.png';
import BNA from '../../../../assets/images/brands/BNA.png';
import satim from '../../../../assets/images/brands/satim.png';
import gieMonitique from '../../../../assets/images/brands/gie monitique.png';
import { useTranslation } from 'react-i18next';

import "./Brands.css"

// قائمة الصور للعلامات التجارية
const images = [
    AlgeriePoste,
    Carte_Ban,
    ooredoo,
    satim,
    gieMonitique,
    mobilis,
    baridiMob,
    djezzy,
    BNA
];

const Brands = () => {
    const { t, i18n } = useTranslation();

    //  slick إعدادات مكتبة  للعروض التقديمية 
    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // 4 ثواني
        responsive: [
            {
                breakpoint: 768, // عتبة الهاتف
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 430, // عتبة الهاتف
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            }
        ],
    };

    // تهيئة للتحقق من العناصر عند التمرير
    new WOW().init();

    return (
        <section id="Brands" className="ud-testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ud-section-title text-center mx-auto wow fadeInUp" data-wow-delay=".4s"style={{marginBottom:"80px"}}>
                            <h2>{t("Partners")}</h2>
                        </div>
                    </div>
                </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="ud-brands wow fadeInUp" data-wow-delay=".6s">

                        {/*    Slider عرض العلامات التجارية باستخدام مكتبة  */}
                        <Slider {...settings}>
                            {images.map((image, index) => (
                                <div className="ud-brands-logo" key={index}>
                                    <div className="ud-single-logo">
                                        {/* عرض صور العلامات التجارية */}
                                        <img src={image} alt={`Image ${index + 1}`} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
        </section >
    );
};

export default Brands;
